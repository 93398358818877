export const campaignColumns = [
    {
        label: "",
        accessor: "actions",
        type: 'none',
        sortable: false,
        sortName: '',
        tdClass: '',
        title: "",
    },
    {
        label: "Campaign Name",
        accessor: "campaignName",
        type: 'link',
        sortable: true,
        sortName: 'sortCampaignName',
        tdClass: '',
        title: "The name of the corresponding Campaign in Twilio"
    },
    {
        label: "Campaign Type",
        accessor: "campaignTypeName",
        type: 'text',
        sortable: true,
        sortName: 'sortCampaignType',
        tdClass: '',
        title: "The name of the corresponding Campaign Type Name"
    },
    {
        label: "Call Flow",
        accessor: "callFlowName",
        type: 'text',
        sortable: true,
        sortName: '',
        tdClass: '',
        title: "The name of the corresponding Call Flow"
    },
    {
        label: "Displayed Number",
        accessor: "displayedNumber",
        type: 'text',
        sortable: true,
        sortName: 'sortDisplayedNumber',
        tdClass: '',
        title: "The displayed number on the Caller ID"
    },
    {
        label: "Return Number",
        accessor: "returnNumber",
        type: 'text',
        sortable: true,
        sortName: 'sortReturnNumber',
        tdClass: '',
        title: "The inbound number to the Twilio campaign"
    },
    {
        label: "Throttle",
        accessor: "throttle",
        type: 'text',
        sortable: true,
        sortName: 'sortThrottle',
        tdClass: 'text-center',
        title: "The current amount of outbound calls that can be performed concurrently on this campaign"
    },
    {
        label: "Remaining Members",
        accessor: "remainingMembers",
        type: 'text',
        sortable: true,
        sortName: 'sortRemainingMembers',
        tdClass: 'text-center',
        title: "The remaining members to be called who have not completed the call or exhausted all attempts"
    },
    {
        label: "Active Calls",
        accessor: "activeCalls",
        type: 'text',
        sortable: true,
        sortName: 'sortActiveCalls',
        tdClass: 'text-center',
        title: "The number of active calls on this campaign"
    },
    {
        label: "Active Transfers",
        accessor: "activeTransfers",
        type: 'text',
        sortable: true,
        sortName: 'sortActiveTransfers',
        tdClass: 'text-center',
        title: "The number of active transfers on this campaign"
    },
    {
        label: "Created Date",
        accessor: "userCreatedDateTime",
        type: 'date',
        sortable: true,
        sortName: 'sortCreatedDate',
        tdClass: '',
        title: "The date campaign was created"
    },
];