import "./css/upload-history.css"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import React, { useState } from 'react';
import TablePagination from "@material-ui/core/TablePagination";
import _ from "lodash";
import { uploadHistoryColumns } from './constants/upload-history-columns';

export default React.memo(function UploadHistory(props) {
    const [state, setState] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortByFileName: false,
        sortByUploadedBy: false,
        sortByTotRecCount: false,
        sortByValRecCount: false,
        sortByRejRecCount: false,
        sortByUploadedAt: false,
    });

    const uploadHistory = props.uploadHistory.slice(
        (state.pageNumber) * state.pageSize,
        (state.pageNumber + 1) * state.pageSize
    );

    const sortByColumn = (flag, sortBy, accessor) => {
        setState({
            ...state,
            isSorted: true,
            [sortBy]: flag
        });

        props.uploadHistory
            .sort(function (a, b) {
                a = a[accessor];
                b = b[accessor];

                if (typeof a === 'string') {
                    a = a.toLowerCase();
                    b = b.toLowerCase();
                }

                return a < b ? -1 : a > b ? 1 : 0
            });

        if (flag) {
            props.uploadHistory.reverse();
        }
    };

    const actualFileHandler = (campaignFileId) => {
        props.onFileListImport(campaignFileId, "actual");
    };

    const errorFileHandler = (campaignFileId) => {
        props.onFileListImport(campaignFileId, "error");
    };

    const handleChangePage = (event, newPage) => {
        setState({
            ...state,
            pageNumber: newPage
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setState({
            ...state,
            pageSize: parseInt(event.target.value, 10),
            pageNumber: 0
        });
    };

    return (
        <div className="upload-history-container">
            <div className="heading-row">
                <h2 className="main-heading">Upload History</h2>
            </div>

            <div className="table-outter" style={{ marginBottom: "20px" }}>
                <table className="table">
                    <thead>
                        <tr>
                            {uploadHistoryColumns.map(({ label, accessor, sortable, sortName, tdClass }) => {
                                return <th key={accessor} className={`${accessor}-header ${tdClass}`}>
                                    {sortable && (
                                        <label>
                                            {label}{" "}
                                            <span className="sort-icon">
                                                {state[sortName] ? (
                                                    <ArrowDropDownIcon
                                                        onClick={() => sortByColumn(false, sortName, accessor)}
                                                    />
                                                ) : (
                                                    <ArrowDropUpIcon
                                                        onClick={() => sortByColumn(true, sortName, accessor)}
                                                    />
                                                )}
                                            </span>
                                        </label>
                                    )}
                                    {!sortable && (<label>{label}{" "}</label>)}
                                </th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {uploadHistory.map((upload) => (
                            <tr key={upload.campaignFileId}>
                                {uploadHistoryColumns.map(({ accessor, tdClass, type }) => {
                                    if (accessor === 'actions') {
                                        return (
                                            <React.Fragment key={accessor}>
                                                <td className={tdClass}>
                                                    {upload.errorFilePath ? (
                                                        <span
                                                            onClick={() =>
                                                                errorFileHandler(upload.campaignFileId)
                                                            }
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fa fa-exclamation-triangle error"></i>
                                                        </span>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>
                                            </React.Fragment>
                                        )
                                    }
                                    else
                                        return <td className={tdClass} key={accessor}>{upload[accessor]}</td>;
                                })}
                            </tr>
                        ))}
                        {_.isEmpty(props.uploadHistory) && (
                            <tr>
                                <td colSpan="4">
                                    No files have been uploaded for this campaign. Please
                                    use the "Upload Members" button above.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <TablePagination
                    component="div"
                    count={props.uploadHistory.length}
                    page={state.pageNumber}
                    onPageChange={handleChangePage}
                    rowsPerPage={state.pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
});