import { CommonActions } from "../actions/common";
import {
  FILE_IMPORT,
  RESET_FILE_UPLOAD_MESSAGE,
  FORM_DATA_SAVE,
  RESET_FORM_DATA_SAVE,
  FILE_IMPORT_ERROR,
  RESET_FILE_IMPORT_ERROR,
  GET_CAMPAIGN_DETAILS,
  GET_UPLOAD_HISTORY,
  FILE_LIST_IMPORT,
  REMOVE_QUEUED_MEMBERS,
  REMOVE_QUEUED_MEMBERS_ERROR,
  CLOSE_CAMPAIGN,
  CLOSE_CAMPAIGN_ERROR,
  CAMPAIGN_SCHEDULE,
  POST_CAMPAIGN,
} from "../constants/action-types";
import engagysPortalApi from "../services/engagys-api";
import { asyncFileChanged } from "../helpers/changeFileFormat";
import dayjs from "dayjs";
import { Buffer } from "buffer";

const fileImport = (data, accountId, campaignId) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());
    let promise = asyncFileChanged(data.media);

    Promise.resolve(promise).then(function (fileValue) {
      data.media = fileValue;

      engagysPortalApi.Details.fileImport(data, accountId, campaignId)
        .then(
          (res) => {
            dispatch(resetFormDataSave());
            dispatch(resetFileUploadMessage());
            dispatch(resetFileImportError());
            dispatch({
              type: FILE_IMPORT,
              payload: res,
            });
            dispatch(getCampaignDetails(campaignId));
            dispatch(getUploadHistory(campaignId));
          },
          (err) => {
            dispatch(resetFormDataSave());
            dispatch(resetFileUploadMessage());
            dispatch(resetFileImportError());
            dispatch({
              type: FILE_IMPORT_ERROR,
              payload: err.response.data,
            });
          }
        )
        .finally(() => {
          dispatch(CommonActions.hideLoader());
        });
    });
  };
};

const removeQueuedMembers = (campaignId) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    Promise.resolve().then(function () {
      engagysPortalApi.Details.removeQueuedMembers(campaignId)
        .then(
          (res) => {
            dispatch(resetFormDataSave());
            dispatch(resetFileUploadMessage());
            dispatch(resetFileImportError());
            dispatch({
              type: REMOVE_QUEUED_MEMBERS,
              payload: res,
            });
            dispatch(getCampaignDetails(campaignId));
          },
          (err) => {
            dispatch(resetFormDataSave());
            dispatch(resetFileUploadMessage());
            dispatch(resetFileImportError());
            dispatch({
              type: REMOVE_QUEUED_MEMBERS_ERROR,
              payload: err.response.data,
            });
          }
        )
        .finally(() => {
          dispatch(CommonActions.hideLoader());
        });
    });
  };
};

const closeCampaign = (campaignId) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    Promise.resolve().then(function () {
      engagysPortalApi.Details.closeCampaign(campaignId)
        .then(
          (res) => {
            dispatch(resetFormDataSave());
            dispatch(resetFileUploadMessage());
            dispatch(resetFileImportError());
            dispatch({
              type: CLOSE_CAMPAIGN,
              payload: res,
            });
            dispatch(getCampaignDetails(campaignId));
          },
          (err) => {
            dispatch(resetFormDataSave());
            dispatch(resetFileUploadMessage());
            dispatch(resetFileImportError());
            dispatch({
              type: CLOSE_CAMPAIGN_ERROR,
              payload: err.response.data,
            });
          }
        )
        .finally(() => {
          dispatch(CommonActions.hideLoader());
        });
    });
  };
};
const postCampaign = (data) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    Promise.resolve().then(function () {
      engagysPortalApi.Details.postCampaign(data)
        .then(
          (res) => {
            dispatch(resetFormDataSave());
            dispatch(resetFileUploadMessage());
            dispatch(resetFileImportError());
            dispatch({
              type: POST_CAMPAIGN,
              payload: res,
            });
            // dispatch(getCampaignDetails(campaignId));
          },
          (err) => {}
        )
        .finally(() => {
          dispatch(CommonActions.hideLoader());
        });
    });
  };
};

const formDataSave = (data, campaignId, campaignName) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    engagysPortalApi.Details.formDataSave(data, campaignId)
      .then(
        (res) => {
          dispatch(resetFormDataSave());
          dispatch(resetFileUploadMessage());
          dispatch(resetFileImportError());
          dispatch({
            type: FORM_DATA_SAVE,
            payload: res,
          });
          saveData(
            res,
            `CampaignExport_${campaignName}_${dayjs().format(
              "YYYYMMDDHHmmss"
            )}.xlsx`,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        (err) => {}
      )
      .finally(() => {
        dispatch(CommonActions.hideLoader());
      });
  };
};

const resetFileUploadMessage = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_FILE_UPLOAD_MESSAGE,
    });
  };
};

const resetFormDataSave = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_FORM_DATA_SAVE,
    });
  };
};

const resetFileImportError = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_FILE_IMPORT_ERROR,
    });
  };
};

const removeMembers = (campaignId) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    engagysPortalApi.Details.removeMembers(campaignId)
      .then(
        (res) => {},
        (err) => {}
      )
      .finally(() => {
        dispatch(CommonActions.hideLoader());
      });
  };
};

const getCampaignDetails = (campaignId) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    engagysPortalApi.Details.getCampaignDetails(campaignId)
      .then(
        (res) => {
          dispatch({
            type: GET_CAMPAIGN_DETAILS,
            payload: res.data,
          });
        },
        (err) => {}
      )
      .finally(() => {
        dispatch(CommonActions.hideLoader());
      });
  };
};
const campaignSchedule = (campaignId) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    Promise.resolve().then(function () {
      engagysPortalApi.Details.campaignSchedule(campaignId)
        .then(
          (res) => {
            dispatch({
              type: CAMPAIGN_SCHEDULE,
              payload: res.data,
            });
          },
          (err) => {}
        )
        .finally(() => {
          dispatch(CommonActions.hideLoader());
        });
    });
  };
};

const getUploadHistory = (campaignId) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    engagysPortalApi.Details.getUploadHistory(campaignId)
      .then(
        (res) => {
          dispatch({
            type: GET_UPLOAD_HISTORY,
            payload: res.data,
          });
        },
        (err) => {}
      )
      .finally(() => {
        dispatch(CommonActions.hideLoader());
      });
  };
};

const fileListImport = (campaignId, type) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());

    engagysPortalApi.Details.fileListImport(campaignId, type)
      .then(
        (res) => {
          dispatch({
            type: FILE_LIST_IMPORT,
            payload: res.data,
          });
        },
        (err) => {}
      )
      .finally(() => {
        dispatch(CommonActions.hideLoader());
      });
  };
};

const saveData = (data, fileName, dataType) => {
  const blob = new Blob([Buffer.from(data, "base64")], { type: dataType });
  const URL = window.URL || window.webkitURL;
  const downloadUrl = URL.createObjectURL(blob);

  let a = document.createElement("a");
  a.style.display = "none";
  a.href = downloadUrl;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();
  window.URL.revokeObjectURL(downloadUrl);
};

export const DetailsActions = {
  fileImport,
  formDataSave,
  resetFileUploadMessage,
  resetFormDataSave,
  resetFileImportError,
  removeMembers,
  getCampaignDetails,
  getUploadHistory,
  fileListImport,
  removeQueuedMembers,
  closeCampaign,
  campaignSchedule,
  postCampaign,
};
