import "./css/campaign-list.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Moment from 'react-moment';
import React, { useEffect, useState } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import _ from "lodash";
import { CAMPAIGN_STATUS } from "../../../constants/campaign-statuses";
import { DATE, LINK } from "../../../constants/table-column-types";
import { HomeActions } from "../../../actions/home";
import { campaignColumns } from "./constants/campaign-columns";
import { connect } from "react-redux";
import { ACCOUNT_ID, CAMPAIGN_ID, CAMPAIGN_NAME, CONTACT_NUMBER, STATUS_ID } from "../../../constants/local-storage-items";

export function CampaignListTable(props) {
    const [state, setState] = useState({
        page_number: 0,
        page_size: 10,
        sortCampaignName: false,
        sortCampaignType: false,
        sortCallFlow: false,
        sortDisplayedNumber: false,
        sortReturnNumber: false,
        sortThrottle: false,
        sortRemainingMembers: false,
        sortActiveCalls: false,
        sortActiveTransfers: false,
        sortCreatedDate: false,
        isSorted: false,
        campaignsList: [],
    });

    useEffect(() => {
        if (!_.isEmpty(props.campaignsList)) {
            setCampaignsList(props.campaignsList);
        }
    }, [props.campaignsList]);

    const setCampaignsList = (newCampaignsList) => {
        if (!state.isSorted) {
            newCampaignsList.sort((a, b) =>
                a.userCreatedDateTime < b.userCreatedDateTime
                    ? 1
                    : a.userCreatedDateTime > b.userCreatedDateTime
                        ? -1
                        : 0
            );
        }
        else {
            const campaignIds = state.campaignsList.map(c => c.id);
            newCampaignsList.sort(function (a, b) {
                return campaignIds.indexOf(a.id) - campaignIds.indexOf(b.id);
            });
        }

        setState({
            ...state,
            campaignsList: newCampaignsList
        });
    }

    const setValues = (campaign) => {
        props.onRedirect();
        window.localStorage.setItem(ACCOUNT_ID, props.accountDetails.id);
        window.localStorage.setItem(CAMPAIGN_ID, campaign.id);
        window.localStorage.setItem(CAMPAIGN_NAME, campaign.campaignName);
        window.localStorage.setItem(STATUS_ID, campaign.status);
        window.localStorage.setItem(CONTACT_NUMBER, campaign.callTransferDefaultValue);
    };

    const changeStatus = (status, campaignId) => {
        let data = {
            status: status,
        };
        props.onChangeCampaignStatus(data, campaignId, "home");
    };

    const handleChangePage = (event, newPage) => {
        setState({
            ...state,
            page_number: newPage,
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setState({
            ...state,
            page_size: parseInt(event.target.value, 10),
            page_number: 0,
        });
    };

    const sortByColumn = (flag, sortBy, accessor) => {
        setState({
            ...state,
            isSorted: true,
            [sortBy]: flag
        });

        state.campaignsList
            .sort(function (a, b) {
                a = a[accessor];
                b = b[accessor];

                if (typeof a === 'string') {
                    a = a.toLowerCase();
                    b = b.toLowerCase();
                }

                return a < b ? -1 : a > b ? 1 : 0
            });

        if (flag) {
            state.campaignsList.reverse();
        }
    };

    const campaignsList = state.campaignsList.slice(
        (state.page_number) * state.page_size,
        (state.page_number + 1) * state.page_size
    );

    return (
        <div className="campaign-list-container table-outter mb-4">
            <table className="table">
                <thead>
                    <tr>
                        {campaignColumns.map(({ label, accessor, sortable, sortName, title }) => {
                            return <th key={accessor} title={title} className={`${accessor}-header`}>
                                {sortable && (
                                    <label>
                                        {label}{" "}
                                        <span className="sort-icon">
                                            {state[sortName] ? (
                                                <ArrowDropDownIcon
                                                    onClick={() => sortByColumn(false, sortName, accessor)}
                                                />
                                            ) : (
                                                <ArrowDropUpIcon
                                                    onClick={() => sortByColumn(true, sortName, accessor)}
                                                />
                                            )}
                                        </span>
                                    </label>
                                )}
                            </th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {!_.isEmpty(campaignsList) &&
                        campaignsList.map((item) => (
                            <tr key={item.id}>
                                {campaignColumns.map(({ accessor, tdClass, type }) => {
                                    if (accessor === 'actions') {
                                        return (
                                            <React.Fragment key={accessor}>
                                                {(item.membersLoaded === 0 && item.status !== CAMPAIGN_STATUS.Closed) && (<td></td>)}
                                                {(item.membersLoaded !== 0 || item.status === CAMPAIGN_STATUS.Closed) && (
                                                    <React.Fragment>
                                                        {item.status === CAMPAIGN_STATUS.Paused && (
                                                            <td className="hover-col"
                                                                title="Click to play"
                                                                onClick={changeStatus.bind(this, true, item.id)}
                                                            >
                                                                <i className="fa fa-play" aria-hidden="true"></i>
                                                            </td>
                                                        )}
                                                        {item.status === CAMPAIGN_STATUS.Started && (
                                                            <td className="hover-col"
                                                                title="Click to pause"
                                                                onClick={changeStatus.bind(this, false, item.id)}
                                                            >
                                                                <i className="fa fa-pause" aria-hidden="true"></i>
                                                            </td>
                                                        )}
                                                        {item.status === CAMPAIGN_STATUS.Closed && (
                                                            <td className="label-text"
                                                                title="Campaign Closed"
                                                            >
                                                                <i
                                                                    className="fa fa-ban"
                                                                    aria-hidden="true"
                                                                    style={{ color: "red" }}
                                                                ></i>
                                                            </td>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )
                                    }
                                    else if (type === LINK) {
                                        return (
                                            <td key={accessor}
                                                className="text-link"
                                                onClick={setValues.bind(this, item)}>
                                                {item[accessor]}
                                            </td>
                                        )
                                    }
                                    else if (type === DATE) {
                                        return (
                                            <td className={tdClass} key={accessor}>
                                                <Moment format="MM-DD-YYYY">
                                                    {item[accessor]}
                                                </Moment>
                                            </td>
                                        );
                                    }
                                    else
                                        return <td className={tdClass} key={accessor}>{item[accessor]}</td>;
                                })}
                            </tr>
                        ))}
                </tbody>
            </table>
            <TablePagination
                component="div"
                count={state.campaignsList.length}
                page={state.page_number}
                onPageChange={handleChangePage}
                rowsPerPage={state.page_size}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        campaignsList: state.home.campaignsList,
        accountDetails: state.home.accountDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onRedirect: () => dispatch(HomeActions.redirect()),
    onChangeCampaignStatus: (data, campaignId, page) =>
        dispatch(HomeActions.changeCampaignStatus(data, campaignId, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignListTable);
