import React, { useEffect } from "react";
import { connect } from "react-redux";
import DocTitle from "./layout/DocTitle";
import AppRoute from "./routes";
import { APP_LOAD } from "../../constants/action-types";
import _ from "lodash";
import { AccountActions } from "../../actions/account";
import engagysPortalApi from "../../services/engagys-api";
import { Redirect } from "react-router-dom";
import { PATH } from '../../constants/path';
import { USER_DATA } from "../../constants/local-storage-items";
import { useComponentWillMount } from "../../hooks/useComponentWillMount";

export function App(props) {
  const initUserSession = () => {
    let token;
    if (!_.isEmpty(JSON.parse(window.localStorage.getItem(USER_DATA)))) {
      token = JSON.parse(window.localStorage.getItem(USER_DATA)).token;
    }

    let payload = {
      isLoggedIn: false,
    };

    if (token !== undefined) {
      engagysPortalApi.setToken(token);
      payload.isLoggedIn = true;
    };

    props.onLoad(payload);
  };

  useComponentWillMount(() => {
    initUserSession();
  });

  useEffect(() => {
    if (props.location.pathname === "/account/logout") {
      props.onLogout();
    }
  }, [props.location.pathname]);

  if (window.location.pathname === "/") {
    return <Redirect to={PATH.HomePage} />;
  };

  return (
    <React.Fragment>
      <DocTitle />
      <AppRoute />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.account.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: APP_LOAD, payload }),
  onLogout: () => dispatch(AccountActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

(function (w) {
  w.URLSearchParams =
    w.URLSearchParams ||
    function (searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function (name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
          self.searchString
        );
        if (results == null) {
          return null;
        } else {
          return decodeURI(results[1]) || 0;
        }
      };
    };
})(window);