import axios from "axios";
import { PATH } from '../constants/path';
// import config from '../config/config';

// const API_BASE_URL = config.get('api_base_url');
// const APPLICATION_KEY = config.get('application_key');
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const APPLICATION_KEY = process.env.REACT_APP_APPLICATION_KEY;

// Set auth token
let token = null;
const setToken = (_token) => {
  token = _token;
};

// Create http client
const httpClient = axios.create({
  baseURL: API_BASE_URL,
});

// Set request headers
const setRequestHeaders = (config) => {
  let headers = {};
  headers["content-type"] = "application/json";

  if (token) {
    headers["x-token"] = token;
  }
  headers["application-key"] = APPLICATION_KEY;
  config.headers = headers;
  return config;
};

// Intercept request
httpClient.interceptors.request.use(setRequestHeaders);

// Handle response data
const handleResponse = (res) => {
  return res.data;
};

// Handle err
const handleError = (error) => {
  if (error.response && error.response.status === 401) {
    if (window.location.pathname === PATH.LoginPage) {
      window.location.href = PATH.LoginPage;
    }
  }

  throw error;
};

// Request helper
const requests = {
  get: (url, params = {}) =>
    httpClient.get(url, { params }).then(handleResponse).catch(handleError),
  post: (url, data) =>
    httpClient.post(url, data).then(handleResponse).catch(handleError),
  put: (url, data) =>
    httpClient.put(url, data).then(handleResponse).catch(handleError),
  delete: (url, data) =>
    httpClient.delete(url, data).then(handleResponse).catch(handleError),
};

// Account
const Account = {
  authorize: (data) => requests.post("api/v1/login", data),
};

// Home
const Home = {
  getCampaignListAndSummary: () =>
    requests.get("api/v1/accounts/campaignsandsummary"),
  changeCampaignStatus: (data, campaignId) =>
    requests.put(`api/v1/accounts/campaign/${campaignId}/status`, data),
  pauseAllCalls: (payload, accountId) =>
    requests.put(
      `api/v1/accounts/${accountId}/campaign/pauseallcalls`,
      payload
    ),
  createCampaign: (payload) =>
    requests.post(`api/v1/accounts/campaign/create`, payload),
  getClientCampaignTypes: () =>
    requests.get(`api/v1/lookups/clientcampaigntypes`),
  getCallFlows: () =>
    requests.get(`api/v1/accounts/callflows`),
};

// Details
const Details = {
  fileImport: (data, accountId, campaignId) =>
    requests.post(
      `api/v1/accounts/${accountId}/campaign/${campaignId}/upload`,
      data
    ),
  formDataSave: (data, campaignId) =>
    requests.get(`api/v1/accounts/campaign/${campaignId}/results`, data),
  removeMembers: (campaignId) =>
    requests.delete(`api/v1/accounts/campaign/${campaignId}/removeallmembers`),
  getCampaignDetails: (campaignId) =>
    requests.get(`api/v1/accounts/campaign/${campaignId}/details`),
  getUploadHistory: (campaignId) =>
    requests.get(`api/v1/accounts/campaign/${campaignId}/files`),
  fileListImport: (campaignId, type) =>
    requests.get(`api/v1/campaignfiles/${campaignId}/fileType/${type}`),
  removeQueuedMembers: (campaignId) =>
    requests.put(`api/v1/accounts/campaign/${campaignId}/removecampaignqueue`),
  closeCampaign: (campaignId) =>
    requests.put(`api/v1/accounts/campaign/${campaignId}/closecampaign`),
  campaignSchedule: (campaignId) =>
    requests.get(`api/v1/accounts/campaign/${campaignId}/campaignschedule`),
  postCampaign: (data) =>
    requests.post(`api/v1/accounts/campaignschedule/create`, data),
};

export default {
  Account,
  setToken,
  Home,
  Details,
};
