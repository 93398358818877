export const asyncFileChanged = async (file) => {
    const changedFile = await toBase64(file);
    const base64Data = changedFile.base64.split(',')[1];
    return base64Data;
}

const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            return resolve({
                fileName: file.name,
                base64: reader.result
            });
        };

        reader.onerror = (error) => {
            console.log(error);
            return reject(error);
        };
    });
};

