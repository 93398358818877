import React from "react";

export default React.memo(function FormInput (props) {
    let className = props.error ? "form-control invalid-input " : "form-control ";
    className += props.className ? props.className : "";

    return (
        <input
            className={className}
            name={props.name}
            type={props.inputType}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(event) => props.onChange(event)}
            onBlur={(event) => props.onBlur(event)}/>
    )
});