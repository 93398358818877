import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CommonActions } from "../../actions/common";
import { DetailsActions } from "../../actions/details";
import _ from "lodash";
import { HomeActions } from "../../actions/home";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Breadcrumbs,
    Link,
    Typography,
} from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { CLOSE, REMOVE } from "../../constants/modal-types";
import PerformanceMetrics from "./PerformanceMetrics";
import UploadHistory from "./UploadHistory";
import ContactSchedule from "./ContactSchedule";
import { DUPLICATE_FILE, FILE_NOT_EXIST, FILE_UPLOADED, FILE_UPLOAD_ERROR, INVALID_FILE_FORMAT } from "../../constants/status-messages";
import { ACCOUNT_ID, CAMPAIGN_ID, CAMPAIGN_NAME, STATUS_ID } from "../../constants/local-storage-items";

export function Details(props) {
    const [fileUploadSuccessMessage, setFileUploadSuccessMessage] = useState("");
    const [state, setState] = useState({
        toggleButton: null,
        fileTypeError: false,
        successMessage: "",
        fileImportError: false,
        uploadHistory: [],
        modal: false,
        modalType: null,
        schedule: [],
        postScheduleDisabled: true,
    });


    useEffect(() => {
        window.scrollTo(0, 0);
        props.onDetailsPageLoad();
        props.onResetFileUploadMessage();
        props.onResetCampaignStatus();
        props.onResetFormDataSave();
        props.onResetFileImportError();

        loadData(state.postScheduleDisabled);
        setState({
            ...state,
            toggleButton: parseInt(window.localStorage.getItem(STATUS_ID))
        });
    }, []);

    useEffect(() => {
        const timeout = setInterval(() => {
            loadData(state.postScheduleDisabled)
        }, 30000);

        return () => {
            clearInterval(timeout);
        }
    }, [state.postScheduleDisabled]);

    useEffect(() => {
        if (props.fileImportData.message === FILE_UPLOADED) {
            setFileUploadSuccessMessage(`${FILE_UPLOADED}!`);

            setTimeout(() => {
                setFileUploadSuccessMessage("");
            }, 5000);
        }
        else if (props.fileImportData.data === 400) {
            const toastMessage =
                props.fileImportData.message === INVALID_FILE_FORMAT ? `${INVALID_FILE_FORMAT}!`
                    : props.fileImportData.message === DUPLICATE_FILE ? DUPLICATE_FILE
                        : `${FILE_UPLOAD_ERROR}!`;

            setState({
                ...state,
                fileImportError: true,
            });

            toast.error(toastMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [props.fileImportData]);

    useEffect(() => {
        if (!_.isEmpty(props.formSaveData)) {
            if (props.formSaveData.message === "Successful.") {
                setState({
                    ...state,
                    successMessage: "Success!",
                });

                setTimeout(() => {
                    setState({
                        ...state,
                        successMessage: "",
                    });
                }, 5000);
            }
        }
    }, [props.formSaveData]);

    useEffect(() => {
        const statusId = props.campaignStatus === null ?
            parseInt(window.localStorage.getItem(STATUS_ID)) : props.campaignStatus ?
                1 : 0;

        setState({
            ...state,
            toggleButton: statusId
        })
    }, [props.campaignStatus]);

    useEffect(() => {
        if (!_.isEmpty(props.fileImportError)) {
            setState({
                ...state,
                fileImportError: true,
            });

            setTimeout(() => {
                setState({
                    ...state,
                    fileImportError: false,
                });
            }, 5000);
        }
    }, [props.fileImportError]);

    useEffect(() => {
        if (!_.isEmpty(props.fileList)) {
            const link = props.fileList && props.fileList.filepath;
            if (link.length > 0) {
                let a = document.createElement("a");
                a.style.display = "none";
                a.href = link;
                a.download = link;
                document.body.appendChild(a);

                a.click();
                window.URL.revokeObjectURL(link);
            } else {
                toast.error(`${FILE_NOT_EXIST}!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }, [props.fileList]);

    useEffect(() => {
        if (!_.isEmpty(props.uploadHistory)) {
            setState({
                ...state,
                uploadHistory: props.uploadHistory
                    .sort((a, b) =>
                        a.uploadedDateTime < b.uploadedDateTime
                            ? -1
                            : a.uploadedDateTime > b.uploadedDateTime
                                ? 1
                                : 0
                    )
                    .reverse(),
            });
        }
    }, [props.uploadHistory]);

    useEffect(() => {
        if (props.closeCampaign && props.closeCampaign.data === 200) {
            window.localStorage.setItem(STATUS_ID, 2);
            setState({
                ...state,
                toggleButton: 2,
            });
        }
    }, [props.closeCampaign]);

    useEffect(() => {
        if (!_.isEmpty(props.campaignSchedule)) {
            setState({
                ...state,
                schedule: props.campaignSchedule
            });
        }
    }, [props.campaignSchedule]);

    useEffect(() => {
        if (
            props.postCampaign &&
            props.postCampaign.message &&
            props.postCampaign.message === "Successful."
        ) {
            toast.success("Success", {
                position: "bottom-right",
                autoClose: 800,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            props.onCampaignSchedule(window.localStorage.getItem(CAMPAIGN_ID));
        }
    }, [props.postCampaign]);

    const loadData = (postScheduleDisabled) => {
        if (postScheduleDisabled) {
            props.onCampaignSchedule(window.localStorage.getItem(CAMPAIGN_ID));
        }
        props.onGetCampaignDetails(window.localStorage.getItem(CAMPAIGN_ID));
        props.onGetUploadHistory(window.localStorage.getItem(CAMPAIGN_ID));
    };

    const handleOpen = (type) => {
        if (type === CLOSE || type === REMOVE) {
            setState({
                ...state,
                modal: true,
                modalType: type
            });
        }
    };

    const handleClose = () => {
        setState({
            ...state,
            modal: false
        });
    };

    const setPostScheduleDisabled = (disabled) => {
        setState({
            ...state,
            postScheduleDisabled: disabled
        });
    };

    const setChosenFileName = (file) => {
        let data = {
            media: file,
            fileName: file !== undefined ? file.name : "",
            mimeType: file !== undefined ? file.type : "",
            dimension: file !== undefined ? file.size : "",
        };

        if (file !== undefined) {
            let extension = file.name.slice(file.name.lastIndexOf(".") + 1);
            if (extension === "csv") {
                props.onFileImport(
                    data,
                    window.localStorage.getItem(ACCOUNT_ID),
                    window.localStorage.getItem(CAMPAIGN_ID)
                );
                setState({
                    ...state,
                    fileTypeError: false,
                });
            } else {
                setState({
                    ...state,
                    fileTypeError: true,
                });
                setTimeout(() => {
                    setState({
                        ...state,
                        fileTypeError: false,
                    });
                }, 5000);
            }
        }
    };

    const handleRemoveQueuedMembers = () => {
        props.onRemoveQueue(window.localStorage.getItem(CAMPAIGN_ID));
    };
    const handleCloseCampaign = () => {
        props.onCloseCampaign(window.localStorage.getItem(CAMPAIGN_ID));
    };

    const onDownload = () => {
        let data = { campaignId: parseInt(localStorage.getItem(CAMPAIGN_ID)) };

        props.onFormDataSave(
            data,
            window.localStorage.getItem(CAMPAIGN_ID),
            window.localStorage.getItem(CAMPAIGN_NAME)
        );
    };

    const onFileClick = (event) => {
        event.target.value = "";
    };

    const changeStatus = (status, value) => {
        let data = { status: status };

        props.onChangeCampaignStatus(
            data,
            window.localStorage.getItem(CAMPAIGN_ID),
            "details"
        );

        window.localStorage.setItem(STATUS_ID, value);

        setState({
            ...state,
            toggleButton: value,
        });
    };

    return (
        <div className="wrapper">
            <div className="content-section">
                <div className="container">
                    <div className="breadcrumb-section">
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link color="inherit" href="/">
                                Home
                            </Link>
                            <Typography color="textPrimary">Details</Typography>
                        </Breadcrumbs>
                    </div>
                    <div className="heading-row">
                        <h2 className="main-heading">
                            {props.campaignDetails.campaignName ?? "Call Controls"}
                        </h2>
                    </div>
                    {props.campaignDetails.campaignTypeName && (
                        <label className="subtitle">{props.campaignDetails.campaignTypeName} | {props.campaignDetails.callFlowName}</label>
                    )}
                    <p className="success-file-upload">
                        {fileUploadSuccessMessage}
                    </p>

                    <p className="success">{state.successMessage}</p>
                    <div className="call-details-wrap">
                        <div className="call-details-left">
                            {(props.campaignDetails.membersLoaded || state.toggleButton === 2) && (
                                <div className="call-btns">
                                    {state.toggleButton === 0 && (
                                        <i
                                            className="fa fa-play play-btn"
                                            title="Click to play"
                                            onClick={() => changeStatus(true, 1)}
                                            aria-hidden="true"
                                        ></i>
                                    )}
                                    {state.toggleButton === 1 && (
                                        <i
                                            className="fa fa-pause pause-btn"
                                            title="Click to pause"
                                            onClick={() => changeStatus(false, 0)}
                                            aria-hidden="true"
                                        ></i>
                                    )}
                                    {state.toggleButton === 2 && (
                                        <i
                                            className="fa fa-ban label-text"
                                            aria-hidden="true"
                                            title="Campaign Closed"
                                            style={{ color: "red" }}
                                        ></i>
                                    )}
                                </div>
                            )}
                            {state.toggleButton !== 2 && (
                                <div className="call-action-btns">
                                    <div className="file-input-wrapper">
                                        <button className="secondary-btn btn mar-r-20">
                                            Upload Members
                                        </button>
                                        <input
                                            type="file"
                                            className="secondary-btn btn mar-r-20"
                                            accept=".csv"
                                            onChange={(event) =>
                                                setChosenFileName(event.currentTarget.files[0])
                                            }
                                            onClick={onFileClick}
                                        />
                                    </div>

                                    {state.fileTypeError && (
                                        <p className="error-wrapper file-format-error">
                                            Wrong file format! You can upload only .csv files.
                                        </p>
                                    )}
                                    {state.fileImportError && (
                                        <p className="error-wrapper file-format-error">
                                            {props.fileImportError.message}
                                        </p>
                                    )}
                                </div>
                            )}
                            {state.toggleButton !== 2 && (
                                <React.Fragment>
                                    <button
                                        className="btn-danger btn mar-r-20 col-md-3"
                                        onClick={() => handleOpen("close")}
                                    >
                                        Close Campaign
                                    </button>
                                    <button
                                        className="btn-secondary btn mar-r-20 col-md-4"
                                        onClick={() => handleOpen("remove")}
                                        disabled={props.campaignDetails.membersRemaining === "0"
                                            || !props.campaignDetails.membersRemaining
                                            ? 'disabled' : ''}
                                    >
                                        Remove Queued Members
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>

                    <PerformanceMetrics
                        campaignDetails={props.campaignDetails}
                        onDownload={onDownload} />

                    <UploadHistory
                        uploadHistory={state.uploadHistory}
                        onFileListImport={props.onFileListImport} />

                    <ContactSchedule
                        campaignSchedule={props.campaignSchedule}
                        schedule={state.schedule}
                        onPostCampaign={props.onPostCampaign}
                        postScheduleDisabled={setPostScheduleDisabled} />

                    <Modal
                        show={state.modal}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        onHide={handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {state.modalType === REMOVE
                                    ? "Remove Queued Members"
                                    : "Close Campaign"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                {state.modalType === REMOVE
                                    ? "Are you sure ? All remaining members will be removed from the queue and will not be contacted."
                                    : "Are you sure you want to close this campaign?  You will not be able to load new members or run this campaign again.  Also, any remaining calls will be canceled."}
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn-danger btn mar-r-20"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                className="primary-btn btn mar-r-20"
                                onClick={() => {
                                    state.modalType === REMOVE
                                        ? handleRemoveQueuedMembers()
                                        : handleCloseCampaign();
                                    handleClose();
                                }}
                            >
                                Yes
                            </button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        fileImportData: state.details.fileImportData,
        campaignStatus: state.home.campaignStatus,
        formSaveData: state.details.formSaveData,
        fileImportError: state.details.fileImportError,
        campaignDetails: state.details.campaignDetails,
        downloadData: state.details.downloadData,
        uploadHistory: state.details.uploadHistory,
        fileList: state.details.fileList,
        removedMembers: state.details.removedMembers,
        closeCampaign: state.details.closeCampaign,
        campaignSchedule: state.details.campaignSchedule,
        postCampaign: state.details.postCampaign,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onDetailsPageLoad: () => dispatch(CommonActions.detailsPageLoad()),
    onFileImport: (data, accountId, campaignId) =>
        dispatch(DetailsActions.fileImport(data, accountId, campaignId)),
    onFormDataSave: (data, campaignId, campaignName) =>
        dispatch(DetailsActions.formDataSave(data, campaignId, campaignName)),
    onResetFileUploadMessage: () =>
        dispatch(DetailsActions.resetFileUploadMessage()),
    onChangeCampaignStatus: (data, campaignId, page) =>
        dispatch(HomeActions.changeCampaignStatus(data, campaignId, page)),
    onResetCampaignStatus: () => dispatch(HomeActions.resetCampaignStatus()),
    onResetFormDataSave: () => dispatch(DetailsActions.resetFormDataSave()),
    onRemoveMemebrs: (campaignId) =>
        dispatch(DetailsActions.removeMembers(campaignId)),
    onResetFileImportError: () => dispatch(DetailsActions.resetFileImportError()),
    onGetCampaignDetails: (campaignId) =>
        dispatch(DetailsActions.getCampaignDetails(campaignId)),
    onGetUploadHistory: (campaignId) =>
        dispatch(DetailsActions.getUploadHistory(campaignId)),
    onFileListImport: (campaignId, type) =>
        dispatch(DetailsActions.fileListImport(campaignId, type)),
    onRemoveQueue: (campaignId) =>
        dispatch(DetailsActions.removeQueuedMembers(campaignId)),
    onCloseCampaign: (campaignId) =>
        dispatch(DetailsActions.closeCampaign(campaignId)),
    onCampaignSchedule: (campaignId) =>
        dispatch(DetailsActions.campaignSchedule(campaignId)),
    onPostCampaign: (data) => dispatch(DetailsActions.postCampaign(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);