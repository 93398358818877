import { GET_CALL_FLOWS, GET_CAMPAIGN_LIST_AND_SUMMARY, GET_CAMPAIGN_TYPES, MODIFY_CAMPAIGN_STATUS, RESET_CAMPAIGN_STATUS }
from '../constants/action-types';

const defaultState = {
   summary: {},
   campaignsList: [],
   accountDetails: {},
   campaignStatus: null,
   campaignTypes: [],
   callFlows: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_CAMPAIGN_LIST_AND_SUMMARY:
            return {
                ...state,
                summary: action.payload.summary,
                campaignsList: action.payload.campaigns ? action.payload.campaigns : [],
                accountDetails: action.payload.account
            }
        case MODIFY_CAMPAIGN_STATUS:
            return {
                ...state,
                campaignStatus: action.payload
            }
        case RESET_CAMPAIGN_STATUS:
            return {
                ...state,
                campaignStatus: null
            }
        case GET_CAMPAIGN_TYPES:
            return {
                ...state,
                campaignTypes: action.payload,
            }
        case GET_CALL_FLOWS:
            return {
                ...state,
                callFlows: action.payload,
            }
        default:
            return state;
    }
};
