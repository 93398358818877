import { useIdleTimer } from 'react-idle-timer'

function IdleTimer(props) {
    const idleTimeoutInMinutes = 10;

    const onIdle = () => {
        sessionStorage.setItem("sessionExpired", true);
        props.onLogout();
    }

    useIdleTimer({ onIdle, timeout: 1000 * 60 * idleTimeoutInMinutes });

    return null;
}

export default IdleTimer