import { APP_LOAD, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, USER_INFO_LOAD } from '../constants/action-types';
    
const defaultState = {
    isLoggedIn: false,
    alert: {
        type: '',
        message: ''
    },
    userInfo: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case APP_LOAD:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                alert: defaultState.alert,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            };
        case LOGIN_FAILURE: 
            return {
                ...state,
                alert: {
                    type: 'error',
                    message: action.payload
                }
            }
        case USER_INFO_LOAD:
            delete action.payload['token'];
            return {
                ...state,
                userInfo: action.payload
            }          
        default:
            return state;
    }
};