import { CommonActions } from '../actions/common';
import { GET_CALL_FLOWS, GET_CAMPAIGN_LIST_AND_SUMMARY, GET_CAMPAIGN_TYPES, MODIFY_CAMPAIGN_STATUS, RESET_CAMPAIGN_STATUS }
    from "../constants/action-types";
import engagysPortalApi from '../services/engagys-api';
import { push } from 'react-router-redux';
import { DetailsActions } from '../actions/details';
import { PATH } from '../constants/path'

const getCampaignListAndSummary = () => {
    return dispatch => {
        dispatch(CommonActions.showLoader());
        engagysPortalApi.Home.getCampaignListAndSummary()
            .then(res => {
                dispatch({
                    type: GET_CAMPAIGN_LIST_AND_SUMMARY,
                    payload: res.data
                });
            }, err => {
                dispatch(push(PATH.LoginPage))
            })
            .finally(() => {
                dispatch(CommonActions.hideLoader())
            })
    }
}

const redirect = () => {
    return dispatch => {
        dispatch(push(PATH.CampaignDetails));
    }
}

const changeCampaignStatus = (data, campaignId, page) => {
    return dispatch => {
        dispatch(CommonActions.showLoader());
        engagysPortalApi.Home.changeCampaignStatus(data, campaignId)
            .then(res => {
                const homePage = 'home';
                if (page !== homePage) {
                    dispatch(DetailsActions.resetFileUploadMessage());
                    dispatch(DetailsActions.resetFormDataSave());
                    dispatch(DetailsActions.resetFileImportError());
                }
                dispatch({
                    type: MODIFY_CAMPAIGN_STATUS,
                    payload: data.status === true ? true : false
                });
                if (page === homePage) {
                    dispatch(getCampaignListAndSummary());
                }
            }, err => {
            })
            .finally(() => {
                dispatch(CommonActions.hideLoader())
            })
    }
}

const resetCampaignStatus = () => {
    return dispatch => {
        dispatch({
            type: RESET_CAMPAIGN_STATUS
        })
    }
}

const pauseAllCalls = (payload, accountId) => {
    return dispatch => {
        dispatch(CommonActions.showLoader());
        engagysPortalApi.Home.pauseAllCalls(payload, accountId)
            .then(res => {
                dispatch(getCampaignListAndSummary());
            }, err => {
            })
            .finally(() => {
                dispatch(CommonActions.hideLoader())
            })
    }
}

const createCampaign = (payload) => {
    return dispatch => {
        dispatch(CommonActions.showLoader());
        engagysPortalApi.Home.createCampaign(payload)
            .then(res => {
                dispatch(getCampaignListAndSummary());
            }, err => {
            })
            .finally(() => {
                dispatch(CommonActions.hideLoader())
            })
    }
}

const getClientCampaignTypes = () => {
    return dispatch => {
        dispatch(CommonActions.showLoader());
        engagysPortalApi.Home.getClientCampaignTypes()
            .then(res => {
                dispatch({
                    type: GET_CAMPAIGN_TYPES,
                    payload: res.data
                });
            }, err => {
                dispatch(err)
            })
            .finally(() => {
                dispatch(CommonActions.hideLoader())
            })
    }
}

const getCallFlows = () => {
    return dispatch => {
        dispatch(CommonActions.showLoader());
        engagysPortalApi.Home.getCallFlows()
            .then(res => {
                dispatch({
                    type: GET_CALL_FLOWS,
                    payload: res.data
                });
            }, err => {
                dispatch(err)
            })
            .finally(() => {
                dispatch(CommonActions.hideLoader())
            })
    }
}


export const HomeActions = {
    getCampaignListAndSummary,
    redirect,
    changeCampaignStatus,
    resetCampaignStatus,
    pauseAllCalls,
    createCampaign,
    getClientCampaignTypes: getClientCampaignTypes,
    getCallFlows
}
