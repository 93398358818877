import { combineReducers } from 'redux';
import account from './account';
import common from './common';
import home from './home';
import details from './details';
import { connectRouter } from 'connected-react-router';

const rootReducer = (history) => combineReducers({
    account,
    common,
    home,
    details,
    router: connectRouter(history)
  })
  
export default rootReducer;