import React from 'react';
import _ from "lodash";

export default React.memo(function PerformanceMetrics(props) {
    const isCampaignDetailsEmpty = _.isEmpty(props.campaignDetails);
    return (
        <React.Fragment>
            <div className="heading-row">
                <h2 className="main-heading">Performance Metrics</h2>
                {props.campaignDetails.membersLoaded && (
                    <button
                        className="primary-btn btn margin-bottom"
                        onClick={() => props.onDownload()}
                    >
                        Download
                    </button>
                )}
            </div>

            <div className="matrics-outter ">
                <div className="matrics-wrap">
                    <div className="col-4">
                        <table className="table no-border">
                            <thead>
                                <tr>
                                    <th colSpan="3">Campaign Overview</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        className="label-text w-50"
                                        title="Total number of members in the current campaign"
                                    >
                                        Members Loaded{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.membersLoaded
                                                    ? props.campaignDetails.membersLoaded
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                    <td></td>
                                </tr>
                                <tr>
                                    <td
                                        className="label-text"
                                        title="Total number of members called"
                                    >
                                        Members Called{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.membersCalled
                                                    ? props.campaignDetails.membersCalled
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.membersCalledInPercent
                                                    ? props.campaignDetails.membersCalledInPercent
                                                    : "0%"}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td
                                        className="label-text"
                                        title="Total number of members where call has not been attempted"
                                    >
                                        Members Remaining{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.membersRemaining
                                                    ? props.campaignDetails.membersRemaining
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails
                                                    .membersRemainingInPercent
                                                    ? props.campaignDetails
                                                        .membersRemainingInPercent
                                                    : "0%"}
                                            </div>{" "}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="col-4">
                        <table className="table no-border">
                            <thead>
                                <tr>
                                    <th colSpan="3">Disposition</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        className="label-text w-50"
                                        title="Call attempted and no answer"
                                    >
                                        Not Reached{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.unreachable
                                                    ? props.campaignDetails.unreachable
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.unreachableInPercent
                                                    ? props.campaignDetails
                                                        .unreachableInPercent
                                                    : "0%"}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td
                                        className="label-text"
                                        title="Call connected message left on answering machine"
                                    >
                                        Left Message{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.leftMessage
                                                    ? props.campaignDetails.leftMessage
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.leftMessageInPercent
                                                    ? props.campaignDetails
                                                        .leftMessageInPercent
                                                    : "0%"}
                                            </div>{" "}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td
                                        className="label-text"
                                        title="Any member who successfully answered the call"
                                    >
                                        Call Connected
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.connected
                                                    ? props.campaignDetails.connected
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.connectedInPercent
                                                    ? props.campaignDetails.connectedInPercent
                                                    : "0%"}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-4">
                        <table className="table no-border">
                            <thead>
                                <tr>
                                    <th colSpan="2">Outcome</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        className="label-text"
                                        title="Member successfully completed the call "
                                    >
                                        Complete{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.completedCall
                                                    ? props.campaignDetails.completedCall
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td
                                        className="label-text"
                                        title="Member answered but did not make it to the final step of the call"
                                    >
                                        Incomplete{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.incompleteCall
                                                    ? props.campaignDetails.incompleteCall
                                                    : "0"}
                                            </div>{" "}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td
                                        className="label-text"
                                        title="Total number of members that were transferred to an agent"
                                    >
                                        Transferred{" "}
                                    </td>
                                    {!isCampaignDetailsEmpty && (
                                        <td>
                                            <div className="count-box">
                                                {props.campaignDetails.transferred
                                                    ? props.campaignDetails.transferred
                                                    : "0"}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});