import _ from 'lodash';
import params from './parameters';

// application configuration
let defaultParams = {
    app_name: 'Engagys Portal',
    date_format: 'MMM DD, YYYY',
    datetime_format: 'MMM DD, YYYY hh:mm A',
    time_format: 'hh:mm A'
}

// extend environment configuration
let config = _.extend(defaultParams, params);

export default {
    get(key) {
        try {
            return key.split('.').reduce((obj, k) => obj[k], config);
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}