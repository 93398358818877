import  { SHOW_LOADER, HIDE_LOADER, HOME_PAGE_LOAD, LOGIN_PAGE_LOAD, DETAILS_PAGE_LOAD } from "../constants/action-types";
    
    
    const showLoader = () => {
        return {
            type: SHOW_LOADER
        }
    }
    
    const hideLoader = () => {
        return {
            type: HIDE_LOADER
        }
    }

    
    const homepageLoad = () => {
        return {
            type: HOME_PAGE_LOAD
        }
    }

    const loginPageLoad = () => {
        return {
            type: LOGIN_PAGE_LOAD
        }
    }

    const detailsPageLoad = () => {
        return {
            type: DETAILS_PAGE_LOAD
        }
    }
    
    export const CommonActions = {
        showLoader,
        hideLoader,
        homepageLoad,
        loginPageLoad,
        detailsPageLoad
    }