import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AccountActions } from '../../actions/account';
import { CommonActions } from '../../actions/common';
import Logo from "../../assets/images/PS_Logo_Color.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { REQUIRED } from "../../constants/validation-messages";
import FormInput from "../../components/shared/form-controls/FormInput";
import FormValidationMessage from "../../components/shared/form-controls/FormValidationMessage";
import { PATH } from '../../constants/path';

export function Login(props) {
    useEffect(() => {
        props.onPageLoad();
    }, []);

    const query = new URLSearchParams(props.location.search)
    const redirectPath = query.get('redirectTo');

    const formik = useFormik({
        initialValues: {
            userName: "",
            password: ""
        },
        validationSchema: Yup.object({
            userName: Yup.string().required(REQUIRED),
            password: Yup.string().required(REQUIRED),
        }),
        onSubmit: (values ) => {
            props.onAuthenticate(values);
        }
    });

    if (props.isLoggedIn) {
        if (redirectPath) {
            return (<Redirect to={redirectPath} />)
        }
        else {
            return (<Redirect to={PATH.HomePage} />)
        }
    }
    else {
        return(
            <div className="login-page-wrapper">
                <div className="login-main-section">
                    {props.alert.type === 'error' &&
                        (<p className="error-wrapper">{props.alert.message.message}</p>)
                    }
                    <form
                        onSubmit={formik.handleSubmit}
                        className="bg-color"
                        autoComplete="off">

                        <img src={Logo} alt='ParcelShield Logo' className='login-form-title' />
                        <div className='mb-4'>
                            <FormInput
                                className={formik.touched.userName && formik.errors.userName ? "invalid-input mt-2" : "mt-2"}
                                name="userName"
                                inputType="text"
                                placeholder="Username"
                                value={formik.values.userName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                            <FormValidationMessage
                                touched={formik.touched.userName}
                                validationMessage={formik.errors.userName}/>
                        </div>
                        <div className='mb-4'>
                            <FormInput
                                className={formik.touched.password && formik.errors.password ? "invalid-input" : ""}
                                name="password"
                                inputType="password"
                                placeholder="Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                            <FormValidationMessage
                                touched={formik.touched.password}
                                validationMessage={formik.errors.password}/>
                        </div>
                        <div className="container-login-form-btn">
                            <button type="submit" className="login-form-btn">Log in</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        alert: state.account.alert,
        userInfo: state.account.userInfo
    };
};

const mapDispatchToProps = (dispatch) => ({
    onPageLoad: () =>
        dispatch(CommonActions.loginPageLoad()),
    onAuthenticate: (values) =>
        dispatch(AccountActions.login(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
