import Loader from './Loader';
import React from 'react';
import SessionExpiredModal from '../../shared/SessionExpiredModal';
import { connect } from 'react-redux';

export function DefaultLayout(props) {
    return (
        <React.Fragment>
            <SessionExpiredModal></SessionExpiredModal>
            <div>
                {props.children}
            </div>
            {props.loader && <Loader />}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        loader: state.common.loader
    };
};

export default connect(mapStateToProps)(DefaultLayout);