import Footer from "./Footer";
import IdleTimer from "../../shared/IdleTimer";
import Loader from "./Loader";
import Logo from "../../../assets/images/PS_Logo_White.png";
import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { PATH } from '../../../constants/path';
import { USER_DATA } from "../../../constants/local-storage-items";

export function MainLayout(props) {
  const logout = () => {
    props.onLogout();
  };

  if (!props.isLoggedIn) {
    return <Redirect to={PATH.LoginPage} />;
  }

  if (_.isEmpty(JSON.parse(window.localStorage.getItem(USER_DATA)))) {
    return null;
  }

  return (
    <React.Fragment>
      <IdleTimer onLogout={logout}></IdleTimer>
      <div className="app-wrapper">
        <div className="top-section">
          <div className="container top-items-align">
            <div className="topbar-left">
              <Link className="align-center" to={PATH.HomePage}>
                <img src={Logo} alt="Parcelshield Logo" />
              </Link>
            </div>
            <div className="topbar-right">
              <Link to="#" onClick={logout} className="align-center">
                <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
              </Link>
            </div>
          </div>
        </div>
        <section className="main-wrapper">{props.children}</section>
        <Footer />
      </div>
      {props.loader && <Loader />}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.account.isLoggedIn,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(push("/account/logout")),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
