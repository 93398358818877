import React from "react";
import { Switch } from "react-router-dom";
import Login from "../../account/Login";
import Home from "../home";
import Details from "../../Details";
import PageNotFound from "../layout/PageNotFound";
import { PATH } from "../../../constants/path";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export function AppRoute() {
  return (
    <Switch>
      <PrivateRoute exact path={PATH.HomePage} component={Home} />
      <PrivateRoute exact path={PATH.CampaignDetails} component={Details} />

      {/* Account */}
      <PublicRoute path={PATH.LoginPage} component={Login} />
      <PrivateRoute component={PageNotFound} />
    </Switch>
  );
}

export default AppRoute;
