export const APP_LOAD = "APP_LOAD";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const HOME_PAGE_LOAD = "HOME_PAGE_LOAD";
export const DETAILS_PAGE_LOAD = "DETAILS_PAGE_LOAD";

// Account
export const LOGIN_PAGE_LOAD = "LOGIN_PAGE_LOAD";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const USER_INFO_LOAD = "USER_INFO_LOAD";

// Home
export const GET_CAMPAIGN_LIST_AND_SUMMARY = "GET_CAMPAIGN_LIST_AND_SUMMARY";
export const MODIFY_CAMPAIGN_STATUS = "MODIFY_CAMPAIGN_STATUS";
export const RESET_CAMPAIGN_STATUS = "RESET_CAMPAIGN_STATUS";
export const GET_CAMPAIGN_TYPES = "GET_CAMPAIGN_TYPES";
export const GET_CALL_FLOWS = "GET_CALL_FLOWS";

// Details
export const FILE_IMPORT = "FILE_IMPORT";
export const FILE_IMPORT_ERROR = "FILE_IMPORT_ERROR";
export const RESET_FILE_UPLOAD_MESSAGE = "RESET_FILE_UPLOAD_MESSAGE";
export const FORM_DATA_SAVE = "FORM_DATA_SAVE";
export const RESET_FORM_DATA_SAVE = "RESET_FORM_DATA_SAVE";
export const RESET_FILE_IMPORT_ERROR = "RESET_FILE_IMPORT_ERROR";
export const GET_CAMPAIGN_DETAILS = "GET_CAMPAIGN_DETAILS";
export const GET_UPLOAD_HISTORY = "GET_UPLOAD_HISTORY";
export const FILE_LIST_IMPORT = "FILE_LIST_IMPORT";
export const FILE_LIST_IMPORT_ERROR = "FILE_LIST_IMPORT_ERROR";
export const REMOVE_QUEUED_MEMBERS = "REMOVE_QUEUED_MEMBERS";
export const REMOVE_QUEUED_MEMBERS_ERROR = "REMOVE_QUEUED_MEMBERS_ERROR";
export const CLOSE_CAMPAIGN = "CLOSE_CAMPAIGN";
export const CLOSE_CAMPAIGN_ERROR = "CLOSE_CAMPAIGN_ERROR";
export const CAMPAIGN_SCHEDULE = "CAMPAIGN_SCHEDULE";
export const POST_CAMPAIGN = "POST_CAMPAIGN";
