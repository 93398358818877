import { Route } from "react-router-dom";
import DefaultLayout from "../layout/DefaultLayout";

export const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <DefaultLayout {...props}>
                    <Component {...props} />
                </DefaultLayout>
            )}
        />
    );
};