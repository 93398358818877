import { CommonActions } from "../actions/common";
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../constants/action-types";
import engagysPortalApi from "../services/engagys-api";
import { push } from "react-router-redux";
import { PATH } from '../constants/path';

const login = (data) => {
  return (dispatch) => {
    dispatch(CommonActions.showLoader());
    engagysPortalApi.Account.authorize(data)
      .then(
        (res) => {
          console.log(res);
          dispatch(loginSuccess(res));
        },
        (err) => {
          dispatch(loginFailure(err));
        }
      )
      .finally(() => {
        dispatch(CommonActions.hideLoader());
      });
  };
};

const loginSuccess = (res) => ({
  type: LOGIN_SUCCESS,
  payload: res.data,
});

const loginFailure = (err) => {
  let error;
  if (err.hasOwnProperty("response")) {
    let errorObject = err.response;
    if (errorObject.hasOwnProperty("data")) {
      error = err.response.data;
    } else {
      error = "";
    }
  } else {
    error = "";
  }

  return (dispatch) => {
    dispatch({
      type: LOGIN_FAILURE,
      payload: error,
    });
  };
};

const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
    dispatch(push(PATH.LoginPage));
  };
};

export const AccountActions = {
  login,
  logout,
};
