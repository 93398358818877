import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import 'babel-polyfill';
import './assets/css/main.css';
import App from './components/app'
import configureStore, { history } from './store';
const store = configureStore();

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter history={history} >
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>

), document.getElementById('root'));
serviceWorker.unregister();