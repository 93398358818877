import { Route, Redirect } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { PATH } from "../../../constants/path";

const authenticateUser = () => {
    // TODO add role based authentication
    return true;
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authenticateUser() ? (
                    <MainLayout {...props}>
                        <Component {...props} {...rest} />
                    </MainLayout>
                ) : (
                    <Redirect
                        to={{
                            pathname: PATH.HomePage,
                        }}
                    />
                )
            }
        />
    );
};