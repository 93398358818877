import React from 'react';
import _ from "lodash";

export default React.memo(function CallSummary(props) {
    const isSummaryEmpty = _.isEmpty(props.summary);
    return (
        <div className="widget-row mb-4">
            <div className="info-box widget-border-blue">
            <h4
                className="label-text"
                title="The maximum combined concurrent calls this account can perform"
            >
                Account Max Throttle
            </h4>
            { !isSummaryEmpty && (
                <h1>{props.summary.accountMaxThrottle}</h1>
            )}
            </div>
            <div className="info-box widget-border-pink">
            <h4
                className="label-text"
                title="The total amount of remaining calls across all campaigns in the displayed account"
            >
                All Remaining Calls
            </h4>
            { !isSummaryEmpty && (
                <h1>{props.summary.allRemainingCalls}</h1>
            )}
            </div>
            <div className="info-box widget-border-orange">
            <h4
                className="label-text"
                title="The total amount of active calls across all campaigns in the displayed account"
            >
                All Active Calls
            </h4>
            { !isSummaryEmpty && (
                <h1>{props.summary.allActiveCalls}</h1>
            )}
            </div>
            <div className="info-box widget-border-green">
            <h4
                className="label-text"
                title="The total amount of active transfers across all campaigns in the displayed account"
            >
                All Active Transfers
            </h4>
            { !isSummaryEmpty && (
                <h1>{props.summary.allActiveTransfers}</h1>
            )}
            </div>
        </div>
    )
});