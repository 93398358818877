import "./css/campaign-form.css";
import React, { useEffect, useState } from "react";
import FormSelect from "../../shared/form-controls/FormSelect";
import FormInput from "../../shared/form-controls/FormInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { REQUIRED } from "../../../constants/validation-messages";
import FormValidationMessage from "../../shared/form-controls/FormValidationMessage";
import { USER_DATA } from "../../../constants/local-storage-items";

export default React.memo(function CampaignForm(props) {
    const [filteredCallFlows, setFilteredCallFlows] = useState([]);

    useEffect(() => {
        if (props.campaignTypes.length === 1) {
            const campaignTypeId = props.campaignTypes[0].id;
            const campaignTypeCallFlows = getCampaignTypeCallFlows(campaignTypeId);

            formik.setFieldValue("campaignTypeId", campaignTypeId);
            setFilteredCallFlows(campaignTypeCallFlows);

            if (campaignTypeCallFlows.length === 1) {
                formik.setFieldValue("callFlowId", campaignTypeCallFlows[0].id);
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            campaignName: "",
            campaignTypeId: 0,
            callFlowId: 0
        },
        validationSchema: Yup.object({
            campaignName: Yup.string().required(REQUIRED),
            campaignTypeId: Yup.number().min(1, REQUIRED),
            callFlowId: Yup.number().min(1, REQUIRED)
        }),
        onSubmit: (values, { resetForm }) => {
            props.onSubmit(values);
            resetForm();
        }
    });

    const getCampaignTypeCallFlows = (campaignTypeId) => {
        return props.callFlows
            .filter(cf => cf.campaignTypeId === campaignTypeId && (cf.clientAccountId === 0 || cf.clientAccount === clientAccountId))
    }

    const onCampaignTypeIdChange = (event) => {
        let campaignTypeCallFlows = getCampaignTypeCallFlows(parseInt(event.target.value));

        setFilteredCallFlows(campaignTypeCallFlows);
        formik.setFieldTouched("campaignTypeId", true);
        formik.setFieldValue("callFlowId", campaignTypeCallFlows.length === 1 ? campaignTypeCallFlows[0].id : 0);
    };

    const clientAccountId = parseInt(JSON.parse(window.localStorage.getItem(USER_DATA)).clientAccount.id);

    return (
        <div id="campaign-form">
            <label className="modal-title">Create Campaign</label>
            <form onSubmit={formik.handleSubmit}>
                <div className="row form-controls-row mt-2">
                    <div className="col-md-6">
                        <label className="control-label">Campaign Name <span className="text-danger">*</span></label>
                        <FormInput
                            className="w-100"
                            name="campaignName"
                            inputType="text"
                            placeholder="Campaign Name"
                            error={formik.touched.campaignName && formik.errors.campaignName}
                            value={formik.values.campaignName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        <FormValidationMessage
                            touched={formik.touched.campaignName}
                            validationMessage={formik.errors.campaignName} />
                    </div>
                </div>
                <div className="row form-controls-row">
                    <div className="col-md-6">
                        <label className="control-label">Campaign Type <span className="text-danger">*</span></label>
                        <FormSelect
                            className="w-100"
                            name="campaignTypeId"
                            placeholderOption="Select Campaign Type"
                            disabled={props.campaignTypes.length === 1}
                            error={formik.touched.campaignTypeId && formik.errors.campaignTypeId}
                            selectedOption={props.campaignTypes.length === 1 ? props.campaignTypes[0].id : formik.values.campaignTypeId}
                            optionValues={props.campaignTypes.map(ct => ct.id)}
                            optionContents={props.campaignTypes.map(ct => ct.campaignTypeName)}
                            onChange={async (event) => {
                                await formik.handleChange(event);
                                onCampaignTypeIdChange(event);
                            }}
                            onBlur={formik.handleBlur} />
                        <FormValidationMessage
                            touched={formik.touched.campaignTypeId}
                            validationMessage={formik.errors.campaignTypeId} />
                    </div>
                    <div className="col-md-6">
                        <label className="control-label">Call Flow <span className="text-danger">*</span></label>
                        <FormSelect
                            className="w-100"
                            name="callFlowId"
                            title={props.callFlows[formik.values.callFlowId - 1]?.callFlowName}
                            placeholderOption="Select Call Flow"
                            disabled={filteredCallFlows.length === 1}
                            error={formik.touched.callFlowId && formik.errors.callFlowId}
                            selectedOption={filteredCallFlows.length === 1 ? filteredCallFlows[0].id : formik.values.callFlowId}
                            optionValues={filteredCallFlows.map(cf => cf.id)}
                            optionContents={filteredCallFlows.map(cf => cf.callFlowName)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        <FormValidationMessage
                            touched={formik.touched.callFlowId}
                            validationMessage={formik.errors.callFlowId} />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-end">
                        <button
                            type="button"
                            className="mr-2 btn cancel-btn modal-btn"
                            onClick={(event) => props.onCancel(event)}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn primary-btn modal-btn">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
});