import React from 'react';
import loader from '../../../assets/images/loader.gif';

export function Loader() {
    return (
        <div className="loader-wrapper">
            <img src={loader} className="loader-image" alt='loader' />
        </div>
    );
}

export default Loader;