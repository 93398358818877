import React from "react";

export default React.memo(function FormSelect (props) {
    let className = props.error ? "select-field invalid-input " : "select-field ";
    className += props.className ? props.className : "";

    return (
        <div className="select-box-wrapper">
            <select
                title={props.title}
                disabled={props.disabled}
                className={className}
                name={props.name}
                value={props.selectedOption}
                onChange={(event) => props.onChange(event)}
                onBlur={(event) => props.onBlur(event)}
            >
                <option value={0}>{props.placeholderOption}</option>
                {props.optionValues.map((optionValue, i) => <option key={optionValue} value={optionValue}>{props.optionContents[i]}</option>)}
            </select>
            <span className="select-icon">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
        </div>
    )
});