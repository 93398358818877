import CallSummary from "./CallSummary";
import CampaignForm from "./CampaignForm";
import CampaignListTable from "./CampaignListTable";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CommonActions } from "../../../actions/common";
import { HomeActions } from "../../../actions/home";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { ACCOUNT_ID, CAMPAIGN_ID, CAMPAIGN_NAME, CONTACT_NUMBER, STATUS_ID } from "../../../constants/local-storage-items";

export function Home(props) {
    const [isPauseAllCalls, setIsPauseAllCalls] = useState(false);
    const [showCampaignModal, setShowCampaignModal] = useState(false);

    useEffect(() => {
        let filterCampaignList = props.campaignsList.filter(
            (campaign) => campaign.status === true
        );

        if (filterCampaignList.length >= 1) {
            setIsPauseAllCalls(false);
        }
        else {
            setIsPauseAllCalls(true);
        }
    }, [props.campaignsList]);

    useEffect(() => {
        props.onPageLoad();
        props.onGetCampaignListAndSummary();
        props.onGetClientCampaignTypes();
        props.onGetCallFlows();
        removeCampaignDetailsLocalStorage();
    }, []);

    const removeCampaignDetailsLocalStorage = () => {
        window.localStorage.removeItem(ACCOUNT_ID);
        window.localStorage.removeItem(CAMPAIGN_ID);
        window.localStorage.removeItem(CAMPAIGN_NAME);
        window.localStorage.removeItem(STATUS_ID);
        window.localStorage.removeItem(CONTACT_NUMBER);
    };

    const pauseAllCalls = () => {
        props.onPauseAllCalls(
            {
                status: isPauseAllCalls,
            },
            props.accountDetails.id
        );
    };

    const createCampaign = (formValues) => {
        props.onCreateCampaign({
            name: formValues.campaignName,
            callFlowId: formValues.callFlowId,
            campaignTypeId: formValues.campaignTypeId
        });

        handleCloseCampaignModal();
    };

    const handleShowCampaignModal = () => setShowCampaignModal(true);

    const handleCloseCampaignModal = () => setShowCampaignModal(false);

    return (
        <div className="wrapper">
            <div className="content-section">
                <div className="main-container">
                    <div className="heading-row mar-bottom-20">
                        {!_.isEmpty(props.accountDetails) && (
                            <h2 className="main-heading">
                                {props.accountDetails.name}
                            </h2>
                        )}
                        <button
                            className="secondary-btn btn"
                            onClick={pauseAllCalls}
                            hidden
                        >
                            {!isPauseAllCalls
                                ? "Pause All Calls"
                                : "Play All Calls"}
                        </button>
                    </div>

                    <CallSummary summary={props.summary} />

                    <div className="mb-2 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn primary-btn"
                            onClick={handleShowCampaignModal}
                        >
                            Create Campaign
                        </button>
                    </div>

                    <CampaignListTable />
                    <div className="p-2"></div>
                    <Modal
                        show={showCampaignModal}
                        contentClassName="campaign-modal"
                        centered
                    >
                        <Modal.Body>
                            <CampaignForm
                                campaignTypes={props.campaignTypes}
                                callFlows={props.callFlows}
                                onSubmit={createCampaign}
                                onCancel={handleCloseCampaignModal} />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        summary: state.home.summary,
        accountDetails: state.home.accountDetails,
        campaignsList: state.home.campaignsList,
        campaignTypes: state.home.campaignTypes,
        callFlows: state.home.callFlows,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onPageLoad: () => dispatch(CommonActions.homepageLoad()),
    onGetCampaignListAndSummary: () =>
        dispatch(HomeActions.getCampaignListAndSummary()),
    onPauseAllCalls: (payload, accountId) =>
        dispatch(HomeActions.pauseAllCalls(payload, accountId)),
    onCreateCampaign: (payload) => dispatch(HomeActions.createCampaign(payload)),
    onGetClientCampaignTypes: () => dispatch(HomeActions.getClientCampaignTypes()),
    onGetCallFlows: () => dispatch(HomeActions.getCallFlows()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);