import { SHOW_LOADER, HIDE_LOADER, HOME_PAGE_LOAD, LOGIN_PAGE_LOAD, DETAILS_PAGE_LOAD } from "../constants/action-types";
  
const defaultState = {
  pageTitle: '',
  loader: false,
  showAppMenu: false,
  currentMenuItemName: 'home',
  matchedRoute: '/'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        matchedRoute: action.payload.location.pathname,
      }
    case SHOW_LOADER:
      return {
        ...state,
        loader: true
      }
    case HIDE_LOADER:
      return {
        ...state,
        loader: false
      }
    case HOME_PAGE_LOAD:
      return {
        ...state,
        pageTitle: 'Home'
      }
    case LOGIN_PAGE_LOAD:
      return {
        ...state,
        pageTitle: 'Login'
      }
    case DETAILS_PAGE_LOAD:
      return {
        ...state,
        pageTitle: 'Details'
      }
    default:
      return state;
  }
};