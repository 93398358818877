import { useEffect } from 'react';
import { connect } from 'react-redux';
import config from '../../../config/config';


export function DocTitle(props) {
    useEffect(() => {
        let title = config.get('app_name');

        if (props.pageTitle) {
            title = `${props.pageTitle} - ${title}`
        }

        document.title = title;
    }, [props.pageTitle])

    return (null);
};

const mapStateToProps = state => {
    return {
        pageTitle: state.common.pageTitle
    }
};

export default connect(mapStateToProps, null)(DocTitle);