import {
  FILE_IMPORT,
  RESET_FILE_UPLOAD_MESSAGE,
  FORM_DATA_SAVE,
  RESET_FORM_DATA_SAVE,
  FILE_IMPORT_ERROR,
  RESET_FILE_IMPORT_ERROR,
  GET_CAMPAIGN_DETAILS,
  GET_UPLOAD_HISTORY,
  FILE_LIST_IMPORT,
  REMOVE_QUEUED_MEMBERS,
  CLOSE_CAMPAIGN,
  CAMPAIGN_SCHEDULE,
  POST_CAMPAIGN,
} from "../constants/action-types";

const defaultState = {
  fileImportData: {},
  formSaveData: {},
  fileImportError: {},
  campaignDetails: {},
  uploadHistory: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FILE_IMPORT:
      return {
        ...state,
        fileImportData: action.payload,
      };
    case RESET_FILE_UPLOAD_MESSAGE:
      return {
        ...state,
        fileImportData: {},
      };
    case FORM_DATA_SAVE:
      return {
        ...state,
        formSaveData: action.payload,
      };
    case RESET_FORM_DATA_SAVE:
      return {
        ...state,
        formSaveData: {},
      };
    case FILE_IMPORT_ERROR:
      return {
        ...state,
        fileImportError: action.payload,
      };
    case RESET_FILE_IMPORT_ERROR:
      return {
        ...state,
        fileImportError: {},
      };
    case GET_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignDetails: action.payload.campaignCallDetails
          ? action.payload.campaignCallDetails
          : {},
      };
    case GET_UPLOAD_HISTORY:
      return {
        ...state,
        uploadHistory: action.payload ? action.payload : [],
      };
    case FILE_LIST_IMPORT:
      return {
        ...state,
        fileList: action.payload ? action.payload : [],
      };
    case REMOVE_QUEUED_MEMBERS:
      return {
        ...state,
        removedMembers: action.payload ? action.payload : [],
      };
    case CLOSE_CAMPAIGN:
      return {
        ...state,
        closeCampaign: action.payload ? action.payload : [],
      };
    case CAMPAIGN_SCHEDULE:
      return {
        ...state,
        campaignSchedule: action.payload ? action.payload : [],
      };
    case POST_CAMPAIGN:
      return {
        ...state,
        postCampaign: action.payload ? action.payload : [],
      };
    default:
      return state;
  }
};
