export const uploadHistoryColumns = [
    {
        label: "Filename",
        accessor: "originalFileName",
        type: 'none',
        sortable: true,
        sortName: 'sortByFileName',
        tdClass: '',
    },
    {
        label: "Uploaded By",
        accessor: "uploadedBy",
        type: 'text',
        sortable: true,
        sortName: 'sortByUploadedBy',
        tdClass: '',
    },
    {
        label: "Total Record Count",
        accessor: "totalRecordCount",
        type: 'text',
        sortable: true,
        sortName: 'sortByTotRecCount',
        tdClass: 'text-center',
    },
    {
        label: "Valid Record Count",
        accessor: "validRecordCount",
        type: 'text',
        sortable: true,
        sortName: 'sortByValRecCount',
        tdClass: 'text-center',
    },
    {
        label: "Rejected Record Count",
        accessor: "rejectedRecordCount",
        type: 'text',
        sortable: true,
        sortName: 'sortByRejRecCount',
        tdClass: 'text-center',
    },
    {
        label: "Uploaded At",
        accessor: "uploadedDateTime",
        type: 'date',
        sortable: true,
        sortName: 'sortByUploadedAt',
        tdClass: 'text-center',
    },
    {
        label: "Error",
        accessor: "actions",
        type: 'none',
        sortable: false,
        sortName: '',
        tdClass: 'text-center',
    },
];