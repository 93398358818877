import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { Switch, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";

export default React.memo(function ContactSchedule(props) {
    const [state, setState] = useState({
        disabled: true,
        schedule: [],
    });

    useEffect(() => {
        setState({
            ...state,
            schedule: props.schedule
        });
    }, [props.schedule]);

    const styles = withStyles((theme) => ({
        container: {
            display: "flex",
            flexWrap: "wrap",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    }));

    const IOSSwitch = withStyles((theme) => ({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#009da9",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "#009da9",
                border: "6px solid #fff",
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
        );
    });

    const handleChangeToggle = (i) => {
        const scheduleData = props.campaignSchedule;

        scheduleData[i] = {
            ...scheduleData[i],
            status: !scheduleData[i].status === false ? 0 : 1,
        };

        props.postScheduleDisabled(false);

        setState({
            schedule: scheduleData,
            disabled: false,
        });
    };

    const handleChangeTime = (i, key, value) => {
        const scheduleData = props.campaignSchedule;

        scheduleData[i] = {
            ...scheduleData[i],
            [key]: value,
        };

        props.postScheduleDisabled(false);

        setState({
            schedule: scheduleData,
            disabled: false,
        });
    }

    const handlePostCampaign = () => {
        const data = {
            campaignSchedules: state.schedule,
        };

        const invalidSchedules = getInvalidSchedules(state.schedule)

        if (_.isEmpty(invalidSchedules)) {
            props.onPostCampaign(data);
            props.postScheduleDisabled(true);
    
            setState({
                ...state,
                disabled: true,
            });
        }
        else {
            const invalidDays = invalidSchedules.map(is => is.weekDayName);

            toast.error(`Invalid schedule for ${invalidDays.join(', ')}.`, {
                toastId: 'schedule-error',
                position: "bottom-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const getInvalidSchedules = (campaignSchedules) => {
        let invalidSchedules = [];

        campaignSchedules.forEach((schedule) => {
            if (schedule.startTime >= schedule.endTime)
                invalidSchedules.push(schedule);
        });

        return invalidSchedules;
    };

    return (
        <React.Fragment>
            <div className="heading-row">
                <h2 className="main-heading">Contact Schedule</h2>
            </div>

            <div className="container">
                {state.schedule.map((val, i) => {
                    return (
                        <div key={i} className="scheduleContainer">
                            <div style={{ width: "150px" }}>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={val.status === 1}
                                            onChange={() => handleChangeToggle(i)}
                                            name="checkedB"
                                        />
                                    }
                                    label={val.weekDayName}
                                />
                            </div>

                            <TextField
                                id="time"
                                label="Start time"
                                type="time"
                                onChange={(e) =>
                                    handleChangeTime(i, "startTime", e.target.value)
                                }
                                defaultValue={val.startTime}
                                className={styles.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />

                            <TextField
                                id="time"
                                label="End time"
                                type="time"
                                onChange={(e) =>
                                    handleChangeTime(i, "endTime", e.target.value)
                                }
                                defaultValue={val.endTime}
                                className={styles.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </div>
                    );
                })}

                {state.schedule && state.schedule.length > 0 && (
                    <button
                        className={
                            state.disabled
                                ? "secondary-btn btn mar-r-20 col-md-3 hovButtDisable"
                                : "secondary-btn btn mar-r-20 col-md-3 hovButt"
                        }
                        style={{
                            margin: "20px 0px",
                            backgroundColor: state.disabled ? "#f9f9f9" : "",
                            color: state.disabled ? "#000" : "",
                            cursor: state.disabled ? "default" : "",
                        }}
                        disabled={state.disabled}
                        onClick={state.disabled ? null : handlePostCampaign}
                    >
                        Save
                    </button>
                )}
            </div>
        </React.Fragment>
    );
});