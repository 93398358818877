// import { AccountActions } from "../actions/account";

const applicationMiddleware = store => next => action => {

    let currentState = store.getState();
    if (action.type === "@@router/LOCATION_CHANGE" && currentState.account.isLoggedIn) {

    }
    next(action);
}

export default applicationMiddleware;