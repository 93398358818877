export function Footer() {
    return (
        <footer className="footer">
            <div>
                <a href="https://www.parcelshield.com/privacy-policy/">Privacy Policy</a>
                <span className="separator"></span>
                <a href="https://www.parcelshield.com/terms-of-service/">Terms of Service</a>
            </div>
            <div className="ml-auto">
                <span>© 2022 Parcelshield</span>
                <span className="separator"></span>
                <span>All rights Reserved.</span>
            </div>
        </footer>
    );
}

export default Footer;