import React, { useState, useEffect } from "react";
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { Modal } from 'react-bootstrap';

function SessionExpiredModal() {
    const [smShow, setSmShow] = useState(sessionStorage.getItem("sessionExpired") ? true : false);

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    const onModalHide = () => {
        setSmShow(false);
    }

    return (
        <div>
            <Modal variant="danger" size="sm" show={smShow} onHide={onModalHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <ExclamationTriangleFill color="#dc3545" /> Session Expired
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>You have been logged out. Please login again.</Modal.Body>
            </Modal>
        </div>
    )
}

export default SessionExpiredModal