import React from 'react';

export function PageNotFound() {
    return (
        <h3 className="page-not-found-wrapper">
            Page Not Found
        </h3>
    );
}

export default PageNotFound;