import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { localStorageMiddleware, applicationMiddleware } from './middlewares';
import reducer from './reducers';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
export const history =  createBrowserHistory(); 

// Build the middleware for intercepting and dispatching navigation actions
const appRouterMiddleware = routerMiddleware(history);

const middlewares = [
  appRouterMiddleware,
  thunk,
  localStorageMiddleware,
  applicationMiddleware
];

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(...middlewares);
  } else {
    middlewares.push(createLogger()); // Enable logging in non-production environments.
    return applyMiddleware(...middlewares);
  }
};

export default function configureStore(preloadedState) {
   const store = createStore(reducer(history), preloadedState, composeWithDevTools(getMiddleware()));
   if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(reducer(history));
    });
  }
   return store;
}
